import React, { useState, useEffect } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { getBitacoraError, changeStatus, updateData } from "../../../api/users";
import { validateName, validateEmail } from "../../../components/helper/regex";
import { Space, Table, Modal, Input, Spin } from "antd";
import SCButton from "../../../components/SCButton";
import Swal from "sweetalert2";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

const Bitacora = () => {
  // Se agrega el campo "Password" al estado de userInfo
  const [userInfo, setUserInfo] = useState({
    uid: "",
    Nombre: "",
    Apellido: "",
    Email: "",
    Codigo: "",
    Empresa: "",
    Password: ""
  });

  const [edit, setEdit] = useState(false);
  const [registros, setRegistros] = useState([]); // Registros con error (pendientes)
  const [successRecords, setSuccessRecords] = useState([]); // Registros creados exitosamente
  const [putRegistros, setPutRegistros] = useState(null);
  const [finalMessage, setFinalMessage] = useState("");
  const [error, setError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const bitacoraId = location.pathname.split("/")[3];

  const columns = [
    { title: "Nombre", dataIndex: "Nombre", key: "Nombre" },
    { title: "Apellidos", dataIndex: "Apellido", key: "Apellido" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    { title: "Código", dataIndex: "Codigo", key: "Codigo" },
    { title: "Empresa", dataIndex: "Empresa", key: "Empresa" },
    {
      title: "Error",
      dataIndex: "detalleError",
      key: "detalleError",
      render: (text) => <span style={{ color: "red" }}>{text}</span>,
    },
    {
      title: "Acción",
      key: "acción",
      render: (text, record) => (
        <Space size="middle">
          <SCButton onClick={() => openEditModal(record)}>
            Editar y Registrar
          </SCButton>
          <SCButton onClick={() => onSkip(record.id)}>Omitir</SCButton>
        </Space>
      ),
    },
  ];

  const openEditModal = (record) => {
    setUserInfo({
      uid: record.id,
      Nombre: record.Nombre || "",
      Apellido: record.Apellido || "",
      Email: record.Email || "",
      // Si el registro tiene "Sin código" se fuerza a cadena vacía
      Codigo: record.Codigo === "Sin código" ? "" : record.Codigo,
      Empresa: record.Empresa || "",
      // Se agrega el campo Password (si no viene, se deja vacío)
      Password: record.Password || ""
    });
    setError(record.detalleError || "");
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!validateEmail(userInfo.Email)) {
      setError("Tipo de Email inválido");
      return;
    }
    if (userInfo.Nombre.trim() === "") {
      setError("Ingrese un Nombre");
      return;
    }
    if (userInfo.Apellido.trim() === "") {
      setError("Ingrese un Apellido");
      return;
    }
    // Validación de contraseña:
    // La expresión regular requiere al menos 6 caracteres, una mayúscula, una minúscula y un símbolo.
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).{6,}$/;
    if (!passwordRegex.test(userInfo.Password)) {
      setError("La contraseña debe tener al menos 6 caracteres, incluir una mayúscula, una minúscula y un símbolo.");
      return;
    }
    setEdit(true);
  };

  const handleCancel = () => {
    setUserInfo({
      uid: "",
      Nombre: "",
      Apellido: "",
      Email: "",
      Codigo: "",
      Empresa: "",
      Password: ""
    });
    setError("");
    setIsModalVisible(false);
  };

  const onSkip = (idRegistro) => {
    const updatedRegistros = putRegistros.registroCSVUsuario.map((item) =>
      item.id === idRegistro ? { ...item, status: "omitido" } : item
    );
    const payload = { ...putRegistros, registroCSVUsuario: updatedRegistros };

    changeStatus(bitacoraId, payload)
      .then((response) => {
        const errors = response.registroCSVUsuario.filter(
          (item) => item.status === "error"
        );
        const successes = response.registroCSVUsuario.filter(
          (item) => item.status === "creado"
        );
        setRegistros(errors);
        setSuccessRecords(successes);
        setPutRegistros(response);
        let message = "Registro omitido correctamente.";
        if (successes.length > 0) {
          message +=
            "\nUsuarios creados: " +
            successes.map((item) => item.Nombre).join(", ");
        }
        Swal.fire("Éxito", message, "success");
        if (errors.length === 0) {
          navigate("/app/suscriptores");
        }
      })
      .catch((error) => {
        console.error("Error al omitir registro:", error);
        Swal.fire("Error", "Error al omitir registro", "error");
      });
  };

  const onSkipAll = () => {
    Swal.fire({
      icon: "warning",
      title: "¿Omitir todos los registros?",
      text: "Esta acción no se puede deshacer.",
      showCancelButton: true,
      confirmButtonText: "Sí, omitir todos",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedRegistros = putRegistros.registroCSVUsuario.map(
          (item) => ({
            ...item,
            status: "omitido",
          })
        );
        const payload = {
          ...putRegistros,
          registroCSVUsuario: updatedRegistros,
        };

        changeStatus(bitacoraId, payload)
          .then((response) => {
            const errors = response.registroCSVUsuario.filter(
              (item) => item.status === "error"
            );
            const successes = response.registroCSVUsuario.filter(
              (item) => item.status === "creado"
            );
            setRegistros(errors);
            setSuccessRecords(successes);
            setPutRegistros(response);
            let message = "Todos los registros han sido omitidos.";
            if (successes.length > 0) {
              message +=
                "\nUsuarios creados: " +
                successes.map((item) => item.Nombre).join(", ");
            }
            Swal.fire("Éxito", message, "success");
            if (errors.length === 0) {
              navigate("/app/suscriptores");
            }
          })
          .catch((error) => {
            console.error("Error al omitir todos los registros:", error);
            Swal.fire("Error", "Error al omitir todos los registros", "error");
          });
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getBitacoraError(bitacoraId)
      .then((response) => {
        setPutRegistros(response);
        if (response.mensaje) {
          setFinalMessage(response.mensaje);
        }
        const errors = response.registroCSVUsuario.filter(
          (item) => item.status === "error"
        );
        const successes = response.registroCSVUsuario.filter(
          (item) => item.status === "creado"
        );
        setRegistros(errors);
        setSuccessRecords(successes);
      })
      .catch((error) => {
        console.error("Error al obtener bitácora:", error);
        Swal.fire("Error", "No se pudieron obtener los registros", "error");
      })
      .finally(() => setLoading(false));
  }, [bitacoraId]);

  useEffect(() => {
    if (edit) {
      const updatedRegistros = putRegistros.registroCSVUsuario.map((item) =>
        item.id === userInfo.uid
          ? {
              ...item,
              Nombre: userInfo.Nombre,
              Apellido: userInfo.Apellido,
              Email: userInfo.Email,
              // Se envía el código como cadena vacía si es undefined
              Codigo: (userInfo.Codigo || "").trim(),
              Empresa: userInfo.Empresa,
              // Se agrega la contraseña en caso de modificación
              Password: userInfo.Password,
              status: "creado",
            }
          : item
      );

      const payload = { ...putRegistros, registroCSVUsuario: updatedRegistros };

      updateData(
        payload,
        // Concatenamos el parámetro asegurándonos de que el código sea una cadena
        `${(userInfo.Codigo || "").trim()}_*&_${userInfo.uid}_*&_${bitacoraId}`
      )
        .then((response) => {
          if (response.ok) {
            Swal.fire("Éxito", "Registro actualizado correctamente", "success");
            getBitacoraError(bitacoraId).then((res) => {
              // Actualizamos los registros pendientes
              const errors = res.registroCSVUsuario.filter(
                (item) => item.status === "error"
              );
              const successes = res.registroCSVUsuario.filter(
                (item) => item.status === "creado"
              );
              setRegistros(errors);
              setSuccessRecords(successes);
              if (errors.length === 0) {
                navigate("/app/suscriptores");
              }
            });
            handleCancel();
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          console.error("Error al actualizar registro:", error);
          setError("Error al actualizar registro");
        })
        .finally(() => setEdit(false));
    }
  }, [edit, userInfo, putRegistros, bitacoraId]);

  const updateProperty = (property, value) => {
    // Actualizamos siempre el estado
    setUserInfo((prev) => ({ ...prev, [property]: value }));
  
    // Solo validamos para "Nombre" o "Apellido"
    if (property === "Nombre" || property === "Apellido") {
      // Si el valor no es vacío, validamos toda la cadena (permitiendo letras y espacios)
      const validRegex = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]*$/;
      if (value !== "" && !validRegex.test(value)) {
        setError("Solo se permite letras");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  };
  
  

  return (
    <AdminLayout>
      <div className="flex flex-col gap-4">
        {finalMessage && (
          <div className="p-4 bg-blue-100 text-blue-800 rounded">
            {finalMessage}
          </div>
        )}
        {successRecords.length > 0 && (
          <div className="p-4 bg-green-100 text-green-800 rounded">
            <h3>Usuarios creados:</h3>
            <p>{successRecords.map((item) => item.Nombre).join(", ")}</p>
          </div>
        )}
        <div
          className="flex justify-between items-center"
          style={{ marginBottom: "16px" }}
        >
          <h2 className="text-2xl font-bold">
            {registros.length} Registros Pendientes
          </h2>
          <SCButton onClick={onSkipAll}>Omitir Todos</SCButton>
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "40px" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            className="mt-4 cursor-pointer"
            rowKey="id"
            columns={columns}
            dataSource={registros}
            onRow={(record) => ({
              onClick: () => openEditModal(record), // Abre el modal de edición al dar click en la fila
            })}
          />
        )}
      </div>
      <Modal
        title="Modificar Usuario"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Actualizar"
        cancelText="Cancelar"
      >
        <div style={{ marginBottom: "10px" }}>
          <label>Nombre: </label>
          <Input
            placeholder="Nombre"
            value={userInfo.Nombre}
            onChange={(e) => updateProperty("Nombre", e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Apellido: </label>
          <Input
            placeholder="Apellido"
            value={userInfo.Apellido}
            onChange={(e) => updateProperty("Apellido", e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Email: </label>
          <Input
            placeholder="Email"
            value={userInfo.Email}
            onChange={(e) => updateProperty("Email", e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Código: </label>
          <Input
            placeholder="Código"
            value={userInfo.Codigo}
            onChange={(e) => updateProperty("Codigo", e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Empresa: </label>
          <Input
            placeholder="Empresa"
            value={userInfo.Empresa}
            onChange={(e) => updateProperty("Empresa", e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Contraseña: </label>
          <Input.Password
            placeholder="Contraseña"
            value={userInfo.Password}
            onChange={(e) => updateProperty("Password", e.target.value)}
          />
        </div>
        {error && (
          <p
            className="text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            {error}
          </p>
        )}
      </Modal>
    </AdminLayout>
  );
};

export default Bitacora;
